import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { tabPermissionMapper } from "../constants";
import { useAppContext } from "../context/state";
import { filterPermissions } from "../util/permissions";

export default function Home() {
  const router = useRouter();
  const { loading, store } = useAppContext();

  useEffect(() => {
    if (!loading && store) {
      const urlarr = getPermissions(store?.authData?.permissions);
      router.push(`${urlarr}`);
    } else if (!loading && !store && router.pathname !== "/login") {
      router.push("/login");
    }
  }, [loading, store]);

  const getPermissions = (permissions) => {
    const tempOptions = {};
    let url = [];

    Object.keys(tabPermissionMapper).forEach((option, i, arr) => {
      option = filterPermissions(
        permissions,
        tabPermissionMapper[option],
        router
      );

      tempOptions[arr[i]] = option;
      Object.values(tempOptions).forEach((option) => {
        option.forEach((el) => {
          if (el.url.length) {
            url.push(el.url);
          }
        });
      });
    });
    return url[0];
  };

  return null;
}
